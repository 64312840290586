export interface CustomStyleInterface {
  mt?: string | number
  mb?: string | number
  ml?: string | number
  mr?: string | number
  pt?: string | number
  pb?: string | number
  pl?: string | number
  pr?: string | number
  p?: string | number
  m?: string | number
  fontSize?: string | number
  fontStyle?: string
  fontWeight?: string
  color?: Color
  textAlign?: string
  display?: string
  lineHeight?: string
  width?: string | number
  maxWidth?: string | number
  height?: string | number
  alignItems?: string
  alignContent?: string
  justifyContent?: string
  justifyItems?: string
  position?: string
  zIndex?: string | number
  bg?: Color | string
  flexDirection?: string
  borderRadius?: string | number
  flexShrink?: string | number
  cursor?: string
  border?: string
  overflow?: string
  gap?: number
  flow?: 'column' | 'row'
  minWidth?: string | number
  left?: string | number
  right?: string | number
  top?: string | number
  bottom?: string | number
  verticalAlign?: string
}

export interface CustomStyleComponentInterface {
  $mt?: string | number
  $mb?: string | number
  $ml?: string | number
  $mr?: string | number
  $pt?: string | number
  $pb?: string | number
  $pl?: string | number
  $pr?: string | number
  $p?: string | number
  $m?: string | number
  $fontSize?: string | number
  $fontStyle?: string
  $fontWeight?: string
  $color?: Color
  $textAlign?: string
  $display?: string
  $lineHeight?: string
  $width?: string | number
  $maxWidth?: string | number
  $height?: string | number
  $alignItems?: string
  $alignContent?: string
  $justifyContent?: string
  $justifyItems?: string
  $position?: string
  $zIndex?: string | number
  $bg?: Color | string
  $flexDirection?: string
  $borderRadius?: string | number
  $flexShrink?: string | number
  $cursor?: string
  $border?: string
  $overflow?: string
  $gap?: number
  $flow?: 'column' | 'row'
  $minWidth?: string | number
  $left?: string | number
  $right?: string | number
  $top?: string | number
  $bottom?: string | number
  $verticalAlign?: string
}

export enum Color {
  White = '#fff',
  White_20 = '#F9F9F9',
  Blue_20 = '#E6F4FF',
  Blue_50 = '#91abdc',
  Blue_30 = '#AFDCFF',
  Blue_60 = '#40e0ec',
  Blue_80 = '#116AA5',
  Blue_70 = '#1677FF',
  Blue_95 = '#002E4D',
  Grey = '#ddd',
  Grey_10 = '#d9d9d9',
  Grey_50 = '#b6b6b6',
  Grey_80 = '#727272',
  DarkGrey = '#1D1D1D',
  Black = '#131313',
  Red = '#e32626',
  Red_30 = '#efcece',
  Green_40 = '#E2FFE1',
  Green_80 = '#0A7D23',
  Purple_20 = '#E1E8FF',
  Purple_50 = '#3B63E7',
  Pink_20 = '#fbd6f0',
  Yellow = '#fff24e',
}

export interface MaterialIconInterface {
  id: string
  name: string
  version: number
  popularity: number
  codepoint: number
  unsupported_families: string[]
  categories: string[]
  tags: string[]
  sizes_px: number[]
}
